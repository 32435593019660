import React, { useState } from "react";
import { useSelector } from "react-redux";
import MapPopOver from "./components/MapPopOver";
import MapToolTip from "./components/MapToolTip";
import MapSvg from "./components/MapSvg";

const UsaMap = ({ showToolTip = true, showPopOver = false, ...props }) => {
    const states = useSelector((state) => state.root.styling.states);
    const [activeState, setActiveState] = useState(undefined);

    return (
        <>
            <div className="position-relative">
                <MapSvg {...props} setActiveState={setActiveState} />
                {showToolTip &&
                    states[activeState]?.stateStatus !== "excluded" && (
                        <MapToolTip stateData={states[activeState]} />
                    )}
            </div>
            {showPopOver && <MapPopOver stateData={states[activeState]} />}
        </>
    );
};

export default UsaMap;
