import React, { memo } from "react";
import data from "../data/usa-map-dimensions";
import USAState from "./USAState";

const MapSvg = memo(
    ({
        customize,
        width,
        height,
        defaultStyles,
        defaultHoverStyles,
        setActiveState,
    }) => {
        const stateHref = (state) => {
            if (customize && customize[state] && customize[state].href) {
                return customize[state].href;
            }
            return "#";
        };
        const stateClickHandler = (state) => {
            if (
                customize &&
                customize[state] &&
                customize[state].clickHandler
            ) {
                return customize[state].clickHandler;
            }
            return () => {};
        };

        return (
            <svg
                className="us-state-map stroke-white"
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox="0 0 959 593"
                strokeWidth="2"
            >
                <g className="outlines">
                    {Object.values(data).map((state) => (
                        <USAState
                            key={state.abbreviation}
                            stateKey={state.abbreviation}
                            stateName={state.name}
                            dimensions={state["dimensions"]}
                            state={state.abbreviation}
                            href={stateHref(state.abbreviation)}
                            styles={
                                customize?.[state.abbreviation]?.styles ||
                                defaultStyles
                            }
                            hoverStyles={
                                customize?.[state.abbreviation]?.hoverStyles ||
                                defaultHoverStyles
                            }
                            onClickState={stateClickHandler(state.abbreviation)}
                            setActiveState={setActiveState}
                            isExcluded={
                                customize?.[state.abbreviation]?.isExcluded
                            }
                        />
                    ))}
                </g>
            </svg>
        );
    }
);

export default MapSvg;
